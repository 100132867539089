@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: lighter;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('../../assets/fonts/sourcesanspro/sourcesanspro-light.woff') format('woff');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Source Sans Pro'), local('SourceSansPro-Regular'), url('../../assets/fonts/sourcesanspro/sourcesanspro.woff') format('woff');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('../../assets/fonts/sourcesanspro/sourcesanspro-bold.woff') format('woff');
}